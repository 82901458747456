.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

tbody+thead {
  display: none;
}

.dataTables_paginate {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.mdb-datatable-filter {
  padding-bottom: 10px;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.author_list {
  display: inline-block;
  margin-bottom: 1rem;

}

.pagination {
  display: inline-block;
  position: relative;
  margin: 0 auto;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  margin: 0 4px;
}

.pagination a:hover {
  cursor: pointer
}

.pagination__link {
  font-weight: normal;
}


.pagination__link--active a {
  background-color: rgb(165, 38, 32);
  color: #FFFFFF !important;
  border: 1px solid #d32f2f;
  border-radius: 5px;

}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

.live-streaming-video-title {
  margin-bottom: 20px;
}

.live-streaming-video-title h2 {
  color: #a52620;
}

@media (min-width:320px) {
  .live-streaming-video-title h2 {
    font-size: 1.35em;
  }
}

/*@media (min-width:480px)  { !* smartphones, Android phones, landscape iPhone *! }*/
/*@media (min-width:600px)  { !* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) *! }*/
/*@media (min-width:801px)  { !* tablet, landscape iPad, lo-res laptops ands desktops *! }*/
/*@media (min-width:1025px) { !* big landscape tablets, laptops, and desktops *! }*/
@media (min-width:1281px) {
  .live-streaming-video-title h2 {
    font-size: 1.9em;
  }

  .custom-video-ratio {
    width: 1200px;
    height: 450px;
  }
}

.custom-pdf-page {
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
}

.responsive-survey {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 100vh;
}

.responsive-survey iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}